import React from 'react';
import { Drawer, Typography, Box, Divider, List, ListItem, ListItemText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

function ComitentDrawer({ open, onClose, comitent }) {
  if (!comitent) return null;

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(value);
  };

  const translateMaritalStatus = (status) => {
    const statuses = {
      1: 'Soltero/a',
      2: 'Casado/a',
      3: 'Divorciado/a',
      4: 'Viudo/a'
    };
    return statuses[status] || status;
  };

  const translateRiskLevel = (level) => {
    const levels = {
      1: 'Bajo',
      2: 'Medio',
      3: 'Alto'
    };
    return levels[level] || level;
  };

  const translateInvestorProfile = (profile) => {
    const profiles = {
      1: 'Conservador',
      2: 'Moderado',
      3: 'Agresivo'
    };
    return profiles[profile] || profile;
  };

  const translateGender = (gender) => {
    const genders = {
      1: 'Masculino',
      2: 'Femenino',
      3: 'Otro'
    };
    return genders[gender] || gender;
  };

  const translateDocumentType = (type) => {
    const types = {
      1: 'DNI',
      2: 'Pasaporte',
      3: 'Cédula de Identidad'
    };
    return types[type] || type;
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box p={2} sx={{ width: 400 }}>
        <Typography variant="h5" gutterBottom>
          Detalles del Comitente
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Número de Comitente" secondary={comitent.numero_comitente} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Nombre" secondary={comitent.Nombre} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Apellido" secondary={comitent.Apellido} />
          </ListItem>
          <ListItem>
            <ListItemText primary="CUIT" secondary={comitent.CUIT} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Tipo de Documento" secondary={translateDocumentType(comitent.TipoDocumento)} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Número de Documento" secondary={comitent.NroDocumento} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Email" secondary={comitent.Email} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Fecha de Nacimiento" secondary={comitent.Fecha_Nacimiento ? new Date(comitent.Fecha_Nacimiento).toLocaleDateString() : ''} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Lugar de Nacimiento" secondary={comitent.LugarNacimiento} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Género" secondary={translateGender(comitent.Genero)} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Estado Civil" secondary={translateMaritalStatus(comitent.Estado_Civil)} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Nacionalidad" secondary={comitent.Nacionalidad} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Domicilio" secondary={comitent.Domicilio} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Localidad" secondary={comitent.Localidad} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Provincia" secondary={comitent.Provincia} />
          </ListItem>
          <ListItem>
            <ListItemText primary="País" secondary={comitent.Pais} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Código Postal" secondary={comitent.CodigoPostal} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Teléfono" secondary={comitent.Telefono} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Celular" secondary={comitent.Celular} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Profesión" secondary={comitent.Profesion} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Actividad" secondary={comitent.Actividad} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Nivel de Riesgo" secondary={translateRiskLevel(comitent.Nivel_Riesgo)} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Perfil de Inversor" secondary={translateInvestorProfile(comitent.Perfil_Inversor)} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Patrimonio" secondary={formatCurrency(comitent.Patrimonio)} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Ingresos Mensuales" secondary={formatCurrency(comitent.Ingresos_Mensuales)} />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}

export default ComitentDrawer;
