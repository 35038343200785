import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  CircularProgress,
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled
} from '@mui/material';
import { Link } from 'react-router-dom';
import ComitentDrawer from './ComitentDrawer';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  '&.MuiTableCell-head': {
    fontSize: '0.95rem',
  },
}));

function ComitentList() {
  const [comitents, setComitents] = useState([]);
  const [filteredComitents, setFilteredComitents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedComitent, setSelectedComitent] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sortBy, setSortBy] = useState('nro_documento');
  const [filterValue, setFilterValue] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingComitent, setEditingComitent] = useState(null);

  useEffect(() => {
    const fetchComitents = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/comitentes`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch comitents');
        }

        const data = await response.json();
        setComitents(data);
        setFilteredComitents(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchComitents();
  }, []);

  useEffect(() => {
    const sortedComitents = [...comitents].sort((a, b) => {
      if (a[sortBy] < b[sortBy]) return -1;
      if (a[sortBy] > b[sortBy]) return 1;
      return 0;
    });

    const filtered = sortedComitents.filter(comitent => 
      (comitent.numero_comitente?.toString() || '').includes(filterValue)
    );

    setFilteredComitents(filtered);
  }, [comitents, sortBy, filterValue]);

  const handleViewDetails = (comitent) => {
    setSelectedComitent(comitent);
    setDrawerOpen(true);
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  const handleEditComitent = (comitent) => {
    setEditingComitent(comitent);
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setEditingComitent(null);
  };

  const handleSaveEdit = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/comitentes/${editingComitent.numero_comitente}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(editingComitent)
      });

      if (!response.ok) {
        throw new Error('Failed to update comitente');
      }

      const updatedComitent = await response.json();
      setComitents(comitents.map(c => c.numero_comitente === updatedComitent.numero_comitente ? updatedComitent : c));
      setFilteredComitents(filteredComitents.map(c => c.numero_comitente === updatedComitent.numero_comitente ? updatedComitent : c));
      handleCloseEditDialog();
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Typography variant="h4" gutterBottom>
        Listado de Comitentes
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="sort-select-label">Ordenar por</InputLabel>
          <Select
            labelId="sort-select-label"
            value={sortBy}
            label="Ordenar por"
            onChange={handleSortChange}
          >
            <MenuItem value="nro_documento">Número de Documento</MenuItem>
            <MenuItem value="Nombre">Nombre</MenuItem>
            <MenuItem value="Apellido">Apellido</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Buscar"
          variant="outlined"
          value={filterValue}
          onChange={handleFilterChange}
          placeholder="Buscar por número de comitente"
        />
      </Box>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Número de Comitente</StyledTableCell>
              <StyledTableCell>Nombre</StyledTableCell>
              <StyledTableCell>Apellido</StyledTableCell>
              <StyledTableCell>CUIT</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Acciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredComitents.map((comitent) => (
              <TableRow key={comitent.numero_comitente}>
                <TableCell>{comitent.numero_comitente || ''}</TableCell>
                <TableCell>{comitent.Nombre || ''}</TableCell>
                <TableCell>{comitent.Apellido || ''}</TableCell>
                <TableCell>{comitent.CUIT || ''}</TableCell>
                <TableCell>{comitent.Email || ''}</TableCell>
                <TableCell>
                  <Button 
                    variant="outlined" 
                    size="small" 
                    onClick={() => handleViewDetails(comitent)}
                    sx={{ mr: 1 }}
                  >
                    Ver Detalles
                  </Button>
                  <Button 
                    variant="outlined" 
                    size="small" 
                    onClick={() => handleEditComitent(comitent)}
                    sx={{ mr: 1 }}
                  >
                    Editar
                  </Button>
                  <Button
                    component={Link}
                    to={`/comitents/${comitent.numero_comitente}`}
                    variant="outlined"
                    size="small"
                  >
                    Vista Completa
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ComitentDrawer 
        open={drawerOpen} 
        onClose={() => setDrawerOpen(false)} 
        comitent={selectedComitent} 
      />
      <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>
        <DialogTitle>Editar Comitente</DialogTitle>
        <DialogContent>
          {editingComitent && (
            <>
              <TextField
                margin="dense"
                label="Nombre"
                fullWidth
                value={editingComitent.Nombre}
                onChange={(e) => setEditingComitent({...editingComitent, Nombre: e.target.value})}
              />
              <TextField
                margin="dense"
                label="Apellido"
                fullWidth
                value={editingComitent.Apellido}
                onChange={(e) => setEditingComitent({...editingComitent, Apellido: e.target.value})}
              />
              <TextField
                margin="dense"
                label="CUIT"
                fullWidth
                value={editingComitent.CUIT}
                onChange={(e) => setEditingComitent({...editingComitent, CUIT: e.target.value})}
              />
              <TextField
                margin="dense"
                label="Email"
                fullWidth
                value={editingComitent.Email}
                onChange={(e) => setEditingComitent({...editingComitent, Email: e.target.value})}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancelar</Button>
          <Button onClick={handleSaveEdit}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ComitentList;
