import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Paper, 
  Grid, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  CircularProgress,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from 'react-router-dom';

function ComitentDetail() {
  const { comitentNumber } = useParams();
  const [comitentData, setComitentData] = useState(null);
  const [holdings, setHoldings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchComitentData = async () => {
      if (!comitentNumber) {
        setError('No se proporcionó un número de comitente válido');
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError(null);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No se encontró el token de autenticación');
        }

        // Fetch comitent data
        const comitentResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/comitente/${comitentNumber}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!comitentResponse.ok) {
          throw new Error('No se pudo obtener los datos del comitente');
        }

        const comitentData = await comitentResponse.json();
        setComitentData(comitentData);

        // Fetch holdings data
        const holdingsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/comitente/${comitentNumber}/tenencia`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!holdingsResponse.ok) {
          throw new Error('No se pudo obtener los datos de tenencia');
        }

        const holdingsData = await holdingsResponse.json();
        setHoldings(holdingsData);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchComitentData();
  }, [comitentNumber]);

  const formatCurrency = (value, currency = 'ARS') => {
    return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(value);
  };

  const formatPercentage = (value) => {
    return new Intl.NumberFormat('es-AR', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value / 100);
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;
  if (!comitentData) return <Typography>No hay datos disponibles para este comitente</Typography>;

  return (
    <Box sx={{ width: '100%', overflow: 'hidden', bgcolor: '#f5f5f5', p: 3 }}>
      <Card sx={{ mb: 3, bgcolor: '#e3f2fd' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom color="primary">
            Detalle del Comitente
          </Typography>
          <Typography variant="h5" color="secondary">
            {comitentData.nombre} {comitentData.apellido}
          </Typography>
          <Typography variant="h6" color="text.secondary">
            Número de Cuenta: {comitentData.numero_comitente}
          </Typography>
        </CardContent>
      </Card>
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, mb: 3, bgcolor: '#fff3e0' }}>
            <Typography variant="h5" gutterBottom color="primary">Información Personal</Typography>
            <Typography><strong>CUIT:</strong> {comitentData.cuit || 'No disponible'}</Typography>
            <Typography><strong>Email:</strong> {comitentData.email || 'No disponible'}</Typography>
            <Typography><strong>Fecha de Nacimiento:</strong> {comitentData.fecha_nacimiento ? new Date(comitentData.fecha_nacimiento).toLocaleDateString() : 'No disponible'}</Typography>
            <Typography><strong>Teléfono:</strong> {comitentData.telefono || 'No disponible'}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, mb: 3, bgcolor: '#e8f5e9' }}>
            <Typography variant="h5" gutterBottom color="primary">Información de Contacto</Typography>
            <Typography><strong>Dirección:</strong> {comitentData.direccion || 'No disponible'}</Typography>
            <Typography><strong>Código Postal:</strong> {comitentData.codigo_postal || 'No disponible'}</Typography>
            <Typography><strong>Localidad:</strong> {comitentData.localidad || 'No disponible'}</Typography>
            <Typography><strong>Provincia:</strong> {comitentData.provincia || 'No disponible'}</Typography>
            <Typography><strong>País:</strong> {comitentData.pais || 'No disponible'}</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Paper elevation={3} sx={{ p: 3, mb: 3, bgcolor: '#e8eaf6' }}>
        <Typography variant="h5" gutterBottom color="primary">Tenencias</Typography>
        <Typography variant="h6" gutterBottom color="secondary">
          Total Posición: {formatCurrency(holdings.Resultado.total_posicion)}
        </Typography>
        
        {holdings.Resultado.activos.map((activo, index) => (
          <Accordion key={index} sx={{ mb: 2, bgcolor: '#f3e5f5' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" color="primary">{activo.moneda}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography><strong>Subtotal:</strong> {formatCurrency(activo.subtotal, activo.moneda)}</Typography>
              <Typography><strong>Resultado:</strong> {formatCurrency(activo.resultado, activo.moneda)}</Typography>
              <Typography><strong>Resultado del día:</strong> {formatCurrency(activo.resultado_dia, activo.moneda)}</Typography>
              
              <TableContainer component={Paper} sx={{ mt: 2, bgcolor: '#fff' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow sx={{ bgcolor: '#e1f5fe' }}>
                      <TableCell>Detalle</TableCell>
                      <TableCell>Ticker</TableCell>
                      <TableCell align="right">Cantidad</TableCell>
                      <TableCell align="right">Precio</TableCell>
                      <TableCell align="right">Subtotal</TableCell>
                      <TableCell align="right">Var. Día</TableCell>
                      <TableCell align="right">Var. Costo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activo.especies.map((especie, espIndex) => (
                      <TableRow key={espIndex} sx={{ '&:nth-of-type(odd)': { bgcolor: '#f5f5f5' } }}>
                        <TableCell>{especie.detalle || especie.nombre}</TableCell>
                        <TableCell>{especie.ticker || '-'}</TableCell>
                        <TableCell align="right">{especie.cantidad?.toLocaleString() || '-'}</TableCell>
                        <TableCell align="right">{formatCurrency(especie.precio, activo.moneda)}</TableCell>
                        <TableCell align="right">{formatCurrency(especie.subtotal || especie.importe, activo.moneda)}</TableCell>
                        <TableCell align="right">{especie.porc_var_dia ? formatPercentage(especie.porc_var_dia) : '-'}</TableCell>
                        <TableCell align="right">{especie.porc_var_costo ? formatPercentage(especie.porc_var_costo) : '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
    </Box>
  );
}

export default ComitentDetail;
