import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Chip, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  '&.MuiTableCell-head': {
    fontSize: '0.95rem',
  },
}));

function EgresosList() {
  const [egresos, setEgresos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEgresos = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/egresos`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch egresos');
        }

        const data = await response.json();
        console.log('Received egresos data:', data);
        if (data && data.egresos && data.egresos.IsOK && Array.isArray(data.egresos.Resultado)) {
          setEgresos(data.egresos.Resultado);
        } else {
          throw new Error('Invalid data structure received from API');
        }
      } catch (err) {
        console.error('Error fetching egresos:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEgresos();
  }, []);

  const getStatusChip = (status) => {
    let color = 'default';
    switch (status) {
      case 'Cumplida':
        color = 'success';
        break;
      case 'Rechazado':
        color = 'error';
        break;
      default:
        color = 'default';
    }
    return <Chip label={status} color={color} size="small" />;
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Listado de Egresos
      </Typography>
      {egresos.length === 0 ? (
        <Typography>No hay egresos para mostrar.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Fecha de Creación</StyledTableCell>
                <StyledTableCell>Comitente</StyledTableCell>
                <StyledTableCell>Monto</StyledTableCell>
                <StyledTableCell>CBU</StyledTableCell>
                <StyledTableCell>Estado</StyledTableCell>
                <StyledTableCell>Resultado</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {egresos.map((egreso) => (
                <TableRow key={egreso.id}>
                  <TableCell>{new Date(egreso.created_at).toLocaleString()}</TableCell>
                  <TableCell>
                    <Link component={RouterLink} to={`/comitents/${egreso.comitente}`}>
                      {egreso.comitente}
                    </Link>
                  </TableCell>
                  <TableCell>{formatCurrency(egreso.amount)}</TableCell>
                  <TableCell>{egreso.cbu}</TableCell>
                  <TableCell>{getStatusChip(egreso.status)}</TableCell>
                  <TableCell>
                    {egreso.result && egreso.result.Resultado ? (
                      <>
                        {egreso.result.Resultado.Success === "TRUE" ? "Exitoso" : "Fallido"}
                        {egreso.result.Resultado.Error && ` - ${egreso.result.Resultado.Error}`}
                        {egreso.result.Resultado.Number && ` (Número: ${egreso.result.Resultado.Number})`}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default EgresosList;
