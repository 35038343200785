import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid, Paper, Box, IconButton, CircularProgress, Card, CardContent, CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';
import FundIcon from '@mui/icons-material/AccountBalance';
import MEPIcon from '@mui/icons-material/SwapHoriz';
import ComitentIcon from '@mui/icons-material/People';
import MarketIcon from '@mui/icons-material/TrendingUp';
import EgresosIcon from '@mui/icons-material/MoneyOff';
import IngresosIcon from '@mui/icons-material/AttachMoney';

const DashboardContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.grey[100],
  minHeight: '100vh',
}));

const DashboardCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[10],
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  fontSize: '3rem',
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const ControlPanelCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
}));

const StatValue = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.5rem',
  color: theme.palette.primary.main,
}));

const StatLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
}));

function Dashboard({ user }) {
  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/dashboard`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch dashboard data');
        }
        const data = await response.json();
        setDashboardData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(value);
  };

  return (
    <DashboardContainer>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: (theme) => theme.palette.primary.main }}>
        Bienvenido, {user.email}
      </Typography>
      
      {isLoading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error" my={4}>{error}</Typography>
      ) : (
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={4}>
            <ControlPanelCard>
              <CardContent>
                <StatLabel>Cuentas Comitentes Creadas Hoy</StatLabel>
                <StatValue>{dashboardData.comitentes_count}</StatValue>
              </CardContent>
            </ControlPanelCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlPanelCard>
              <CardContent>
                <StatLabel>Volumen de Egresos</StatLabel>
                <StatValue>{formatCurrency(dashboardData.egresos_volume)}</StatValue>
              </CardContent>
            </ControlPanelCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlPanelCard>
              <CardContent>
                <StatLabel>Volumen de Ingresos</StatLabel>
                <StatValue>{formatCurrency(dashboardData.ingresos_volume)}</StatValue>
              </CardContent>
            </ControlPanelCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlPanelCard>
              <CardContent>
                <StatLabel>Cantidad de Operaciones</StatLabel>
                <StatValue>{dashboardData.market_operations_count}</StatValue>
              </CardContent>
            </ControlPanelCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlPanelCard>
              <CardContent>
                <StatLabel>Volumen de Operaciones</StatLabel>
                <StatValue>{formatCurrency(dashboardData.market_operations_volume)}</StatValue>
              </CardContent>
            </ControlPanelCard>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <DashboardCard>
            <CardActionArea component={Link} to="/fund-operations">
              <CardContent>
                <StyledIconButton>
                  <FundIcon fontSize="inherit" />
                </StyledIconButton>
                <Typography variant="h6" component="h3" gutterBottom>
                  Operaciones de Fondos
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Listado de tus operaciones de fondos de inversión
                </Typography>
              </CardContent>
            </CardActionArea>
          </DashboardCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <DashboardCard>
            <CardActionArea component={Link} to="/operations">
              <CardContent>
                <StyledIconButton>
                  <MEPIcon fontSize="inherit" />
                </StyledIconButton>
                <Typography variant="h6" component="h3" gutterBottom>
                  Operaciones MEP
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Listado operaciones MEP
                </Typography>
              </CardContent>
            </CardActionArea>
          </DashboardCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <DashboardCard>
            <CardActionArea component={Link} to="/comitents">
              <CardContent>
                <StyledIconButton>
                  <ComitentIcon fontSize="inherit" />
                </StyledIconButton>
                <Typography variant="h6" component="h3" gutterBottom>
                  Listado de Comitentes
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Ver todos los comitentes registrados
                </Typography>
              </CardContent>
            </CardActionArea>
          </DashboardCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <DashboardCard>
            <CardActionArea component={Link} to="/market-operations">
              <CardContent>
                <StyledIconButton>
                  <MarketIcon fontSize="inherit" />
                </StyledIconButton>
                <Typography variant="h6" component="h3" gutterBottom>
                  Operaciones de Mercado
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Ver todas las operaciones de mercado
                </Typography>
              </CardContent>
            </CardActionArea>
          </DashboardCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <DashboardCard>
            <CardActionArea component={Link} to="/egresos">
              <CardContent>
                <StyledIconButton>
                  <EgresosIcon fontSize="inherit" />
                </StyledIconButton>
                <Typography variant="h6" component="h3" gutterBottom>
                  Listado de Egresos
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Ver todos los egresos registrados
                </Typography>
              </CardContent>
            </CardActionArea>
          </DashboardCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <DashboardCard>
            <CardActionArea component={Link} to="/ingresos">
              <CardContent>
                <StyledIconButton>
                  <IngresosIcon fontSize="inherit" />
                </StyledIconButton>
                <Typography variant="h6" component="h3" gutterBottom>
                  Listado de Ingresos
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Ver todos los ingresos registrados
                </Typography>
              </CardContent>
            </CardActionArea>
          </DashboardCard>
        </Grid>
      </Grid>
    </DashboardContainer>
  );
}

export default Dashboard;
