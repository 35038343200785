import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import './Navbar.css';

function Navbar({ user, onLogout }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/">
          <img src={logo} alt="Logo" className="navbar-logo" />
        </Link>
      </div>
      <div className="navbar-menu">
        {user ? (
          <>
            <span className="navbar-item">Bienvenido, {user.email}</span>
            <Link to="/dashboard" className="navbar-item">Dashboard</Link>
            <button onClick={handleLogout} className="navbar-item logout-button">Cerrar sesión</button>
          </>
        ) : (
          <>
            <Link to="/login" className="navbar-item">Iniciar sesión</Link>
          </>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
