import React, { useState, useEffect } from 'react';
import logo from './assets/logo.png';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './App.css';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Navbar from './components/Navbar';
import FundOperations from './components/FundOperations';
import OperationHistory from './components/OperationHistory';
import ComitentList from './components/ComitentList';
import ComitentDetail from './components/ComitentDetail';
import MarketOperations from './components/MarketOperations';
import EgresosList from './components/EgresosList';
import IngresosList from './components/IngresosList';

const theme = createTheme();

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = 'Gallo Orchestrator';
    const checkUser = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await fetch(`https://orquestadorback.vercel.app/api/auth/user`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (response.ok) {
            const userData = await response.json();
            setUser(userData);
          } else {
            localStorage.removeItem('token');
          }
        } catch (error) {
          console.error('Error checking user:', error);
        }
      }
      setIsLoading(false);
    };

    checkUser();
  }, []);

  const handleLogin = (userData) => {
    setUser(userData);
    localStorage.setItem('token', userData.access_token);
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('token');
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div className="app-container">
          <Navbar user={user} onLogout={handleLogout}>
            <img
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="Logo"
            />
          </Navbar>
          <div className="content-container">
            <Routes>
              <Route path="/login" element={!user ? <Login onLogin={handleLogin} /> : <Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={user ? <Dashboard user={user} /> : <Navigate to="/login" replace />} />
              <Route path="/" element={<Navigate to={user ? "/dashboard" : "/login"} replace />} />
              <Route path="/fund-operations" element={user ? <FundOperations /> : <Navigate to="/login" replace />} />
              <Route path="/operations" element={user ? <OperationHistory /> : <Navigate to="/login" replace />} />
              <Route path="/comitents" element={user ? <ComitentList /> : <Navigate to="/login" replace />} />
              <Route path="/comitents/:comitentNumber" element={user ? <ComitentDetail /> : <Navigate to="/login" replace />} />
              <Route path="/market-operations" element={user ? <MarketOperations /> : <Navigate to="/login" replace />} />
              <Route path="/egresos" element={user ? <EgresosList /> : <Navigate to="/login" replace />} />
              <Route path="/ingresos" element={user ? <IngresosList /> : <Navigate to="/login" replace />} />
            </Routes>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
