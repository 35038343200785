import React, { useState, useEffect, useCallback } from 'react';
import { 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button, 
  TextField, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  CircularProgress,
  Box,
  Pagination,
  styled,
  Chip
} from '@mui/material';
import { Link } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as XLSX from 'xlsx';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  '&.MuiTableCell-head': {
    fontSize: '0.95rem',
  },
}));

function OperationHistory({ user }) {
  const [operations, setOperations] = useState([]);
  const [filteredOperations, setFilteredOperations] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'descending' });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [filters, setFilters] = useState({
    date: null,
    comitente: '',
    amount: '',
    cotizacion: '',
    status: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [operationsPerPage] = useState(10);

  const fetchOperations = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/operations`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch operations');
      }

      const data = await response.json();
      setOperations(data);
      setFilteredOperations(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchOperations();
  }, [fetchOperations]);

  const sortOperations = useCallback((ops) => {
    return [...ops].sort((a, b) => {
      if (sortConfig.key === 'created_at') {
        return sortConfig.direction === 'ascending'
          ? new Date(a.created_at) - new Date(b.created_at)
          : new Date(b.created_at) - new Date(a.created_at);
      }
      return 0;
    });
  }, [sortConfig]);

  useEffect(() => {
    const filtered = operations.filter(op => {
      const opDate = new Date(op.created_at);
      return (
        (!filters.date || opDate.toDateString() === filters.date.toDateString()) &&
        (op.comitente?.toLowerCase() || '').includes((filters.comitente || '').toLowerCase()) &&
        (op.operation_type?.toLowerCase() || '').includes((filters.operation_type || '').toLowerCase()) &&
        (op.amount?.toString() || '').includes(filters.amount || '') &&
        (op.cotizacion?.toString() || '').includes(filters.cotizacion || '') &&
        (getStatusText(op.status)?.toLowerCase() || '').includes((filters.status || '').toLowerCase())
      );
    });
    setFilteredOperations(sortOperations(filtered));
    setCurrentPage(1);
  }, [filters, operations, sortConfig, sortOperations]);

  const indexOfLastOperation = currentPage * operationsPerPage;
  const indexOfFirstOperation = indexOfLastOperation - operationsPerPage;
  const currentOperations = sortOperations(filteredOperations).slice(indexOfFirstOperation, indexOfLastOperation);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredOperations.map(op => ({
      Fecha: new Date(op.created_at).toLocaleString(),
      Comitente: op.comitente,
      Operación: op.operation_type === 'compra' ? 'Compra' : 'Venta',
      Monto: op.amount,
      Cotización: op.cotizacion || 'N/A',
      Estado: getStatusText(op.status),
      'Estado Orden 1': op.market_status ? op.market_status.estado || 'N/A' : 'N/A',
      'Estado Orden 2': op.market_status_2 ? op.market_status_2.estado || 'N/A' : 'N/A'
    })));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Operaciones");
    XLSX.writeFile(workbook, "historial_operaciones.xlsx");
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleViewDetails = (operation) => {
    setSelectedOperation(operation);
  };

  const closeDetails = () => {
    setSelectedOperation(null);
  };

  const handleFilterChange = (field, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [field]: value
    }));
  };

  const getStatusText = (status) => {
    switch(status) {
      case 'completed': return 'Cumplida';
      case 'failed': return 'Fallida';
      case 'pending': return 'Pendiente';
      case 'cancelled': return 'Anulada';
      default: return status;
    }
  };

  const getStatusChip = (status) => {
    const text = getStatusText(status);
    let backgroundColor = '#e0e0e0';
    let color = '#000000';
    if (status === 'completed') {
      backgroundColor = '#c8e6c9';
      color = '#1b5e20';
    } else if (status === 'cancelled') {
      backgroundColor = '#fff9c4';
      color = '#f57f17';
    } else if (status === 'failed') {
      backgroundColor = '#ffcdd2';
      color = '#b71c1c';
    } else if (status === 'pending') {
      backgroundColor = '#bbdefb';
      color = '#0d47a1';
    }
    return (
      <Chip 
        label={text} 
        sx={{
          backgroundColor,
          color,
          '& .MuiChip-label': {
            padding: '0 6px',
          }
        }}
      />
    );
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Typography variant="h4" gutterBottom>
        Historial de Operaciones
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Button variant="contained" color="primary" onClick={handleExportToExcel}>
          Exportar a Excel
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={() => requestSort('created_at')} sx={{ color: 'inherit' }}>
                    Fecha {sortConfig.key === 'created_at' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                  </Button>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={filters.date}
                      onChange={(newValue) => handleFilterChange('date', newValue)}
                      renderInput={(params) => <TextField {...params} size="small" sx={{ backgroundColor: 'white' }} />}
                    />
                  </LocalizationProvider>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>Comitente</Typography>
                  <TextField
                    size="small"
                    placeholder="Filtrar por comitente"
                    value={filters.comitente}
                    onChange={(e) => handleFilterChange('comitente', e.target.value)}
                    sx={{ backgroundColor: 'white' }}
                  />
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>Operación</Typography>
                  <TextField
                    size="small"
                    placeholder="Filtrar por tipo"
                    value={filters.operation_type}
                    onChange={(e) => handleFilterChange('operation_type', e.target.value)}
                    sx={{ backgroundColor: 'white' }}
                  />
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>Monto</Typography>
                  <TextField
                    size="small"
                    placeholder="Filtrar por monto"
                    value={filters.amount}
                    onChange={(e) => handleFilterChange('amount', e.target.value)}
                    sx={{ backgroundColor: 'white' }}
                  />
                </Box>
              </StyledTableCell>
              <StyledTableCell>Cotización</StyledTableCell>
              <StyledTableCell>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>Estado</Typography>
                  <TextField
                    size="small"
                    placeholder="Filtrar por estado"
                    value={filters.status}
                    onChange={(e) => handleFilterChange('status', e.target.value)}
                    sx={{ backgroundColor: 'white' }}
                  />
                </Box>
              </StyledTableCell>
              <StyledTableCell>Estado Orden 1</StyledTableCell>
              <StyledTableCell>Estado Orden 2</StyledTableCell>
              <StyledTableCell>Acciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentOperations.map((op) => (
              <TableRow key={op.id}>
                <TableCell>{new Date(op.created_at).toLocaleString()}</TableCell>
                <TableCell align="right">
                  <Link to={`/comitents/${op.comitente}`} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
                    {op.comitente}
                  </Link>
                </TableCell>
                <TableCell>{op.operation_type === 'compra' ? 'Compra' : 'Venta'}</TableCell>
                <TableCell align="right">${new Intl.NumberFormat('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(typeof op.amount === 'string' ? op.amount.replace(',', '.') : op.amount))}</TableCell>
                <TableCell>${op.cotizacion ? op.cotizacion.toLocaleString('es-AR', { minimumFractionDigits: 2 }) : 'N/A'}</TableCell>
                <TableCell>
                  {getStatusChip(op.status)}
                </TableCell>
                <TableCell>
                  {op.market_status ? op.market_status.estado || 'N/A' : 'N/A'}
                </TableCell>
                <TableCell>
                  {op.market_status_2 ? op.market_status_2.estado || 'N/A' : 'N/A'}
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleViewDetails(op)} variant="outlined" size="small">
                    Ver Detalles
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={Math.ceil(filteredOperations.length / operationsPerPage)}
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
        />
      </Box>

      <Dialog open={!!selectedOperation} onClose={closeDetails}>
        <DialogTitle>Detalles de la Operación</DialogTitle>
        <DialogContent>
          {selectedOperation && (
            <>
              <Typography><strong>ID:</strong> {selectedOperation.id}</Typography>
              <Typography><strong>Tipo:</strong> {selectedOperation.operation_type}</Typography>
              <Typography><strong>Monto:</strong> ${new Intl.NumberFormat('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(typeof selectedOperation.amount === 'string' ? selectedOperation.amount.replace(',', '.') : selectedOperation.amount))}</Typography>
              <Typography><strong>Estado:</strong> {getStatusText(selectedOperation.status)}</Typography>
              <Typography><strong>Fecha de creación:</strong> {new Date(selectedOperation.created_at).toLocaleString()}</Typography>
              {selectedOperation.details && <Typography><strong>Detalles:</strong> {selectedOperation.details}</Typography>}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDetails}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default OperationHistory;
