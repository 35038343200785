import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Chip, Link, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  '&.MuiTableCell-head': {
    fontSize: '0.95rem',
  },
}));

function IngresosList() {
  const [ingresos, setIngresos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedIngreso, setSelectedIngreso] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchIngresos = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/ingresos`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch ingresos');
        }

        const data = await response.json();
        console.log('Received ingresos data:', data);
        if (data && data.ingresos && Array.isArray(data.ingresos)) {
          setIngresos(data.ingresos);
        } else {
          throw new Error('Invalid data structure received from API');
        }
      } catch (err) {
        console.error('Error fetching ingresos:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchIngresos();
  }, []);

  const getStatusChip = (status) => {
    let color = 'default';
    switch (status) {
      case 'Cumplida':
        color = 'success';
        break;
      case 'Rechazado':
        color = 'error';
        break;
      default:
        color = 'default';
    }
    return <Chip label={status} color={color} size="small" />;
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  const handleOpenDialog = (ingreso) => {
    setSelectedIngreso(ingreso);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Listado de Ingresos
      </Typography>
      {ingresos.length === 0 ? (
        <Typography>No hay ingresos para mostrar.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Fecha de Creación</StyledTableCell>
                <StyledTableCell>Comitente</StyledTableCell>
                <StyledTableCell>Monto</StyledTableCell>
                <StyledTableCell>CBU</StyledTableCell>
                <StyledTableCell>Estado</StyledTableCell>
                <StyledTableCell>ID de Orden</StyledTableCell>
                <StyledTableCell>Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ingresos.map((ingreso) => (
                <TableRow key={ingreso.id}>
                  <TableCell>{new Date(ingreso.created_at).toLocaleDateString('es-AR', { day: '2-digit', month: '2-digit', year: 'numeric' })}</TableCell>
                  <TableCell>
                    <Link component={RouterLink} to={`/comitents/${ingreso.comitente}`}>
                      {ingreso.comitente}
                    </Link>
                  </TableCell>
                  <TableCell>{formatCurrency(ingreso.amount)}</TableCell>
                  <TableCell>{ingreso.cbu}</TableCell>
                  <TableCell>{getStatusChip(ingreso.status)}</TableCell>
                  <TableCell>{ingreso.id_orden}</TableCell>
                  <TableCell>
                    <Button variant="outlined" size="small" onClick={() => handleOpenDialog(ingreso)}>
                      Ver Detalle
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Detalle del Ingreso</DialogTitle>
        <DialogContent>
          {selectedIngreso && (
            <>
              <Typography><strong>ID:</strong> {selectedIngreso.id}</Typography>
              <Typography><strong>Fecha de Creación:</strong> {new Date(selectedIngreso.created_at).toLocaleString('es-AR')}</Typography>
              <Typography><strong>Comitente:</strong> {selectedIngreso.comitente}</Typography>
              <Typography><strong>Monto:</strong> {formatCurrency(selectedIngreso.amount)}</Typography>
              <Typography><strong>CBU:</strong> {selectedIngreso.cbu}</Typography>
              <Typography><strong>Estado:</strong> {selectedIngreso.status}</Typography>
              <Typography><strong>ID de Orden:</strong> {selectedIngreso.id_orden}</Typography>
              {selectedIngreso.details && <Typography><strong>Detalles:</strong> {selectedIngreso.details}</Typography>}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default IngresosList;
