import React, { useState, useEffect, useCallback } from 'react';
import { 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button, 
  TextField, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  CircularProgress,
  Box,
  Pagination,
  styled,
  Chip,
  Grid
} from '@mui/material';
import { Link } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import * as XLSX from 'xlsx';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  '&.MuiTableCell-head': {
    fontSize: '0.85rem',
    padding: theme.spacing(1),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: '32px',
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.dark,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.dark,
    },
    '& input': {
      padding: '4px 8px',
    },
  },
}));

function FundOperations() {
  const [funds, setFunds] = useState([]);
  const [operations, setOperations] = useState([]);
  const [filteredOperations, setFilteredOperations] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'descending' });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [filters, setFilters] = useState({
    date: null,
    operationType: '',
    amount: '',
    status: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [operationsPerPage] = useState(10);

  const fetchFundOperations = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/fondos/operations`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch fund operations');
      }

      const data = await response.json();
      const updatedData = data.map(updateOperationStatus);
      setOperations(updatedData);
      setFilteredOperations(updatedData);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchFundOperations();
  }, [fetchFundOperations]);

  const fetchFunds = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/instruments?TipoInstrumento=F.C.I.`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch funds');
      }

      const data = await response.json();
      setFunds(data);
    } catch (err) {
      console.error('Error fetching funds:', err);
      setError(err.message);
    }
  }, []);

  useEffect(() => {
    fetchFunds();
  }, [fetchFunds]);

  const sortOperations = useCallback((ops) => {
    return [...ops].sort((a, b) => {
      if (sortConfig.key === 'created_at') {
        return sortConfig.direction === 'ascending'
          ? new Date(a.created_at) - new Date(b.created_at)
          : new Date(b.created_at) - new Date(a.created_at);
      }
      return 0;
    });
  }, [sortConfig]);

  useEffect(() => {
    const filtered = operations.filter(op => {
      const opDate = new Date(op.created_at);
      return (
        (!filters.date || opDate.toDateString() === filters.date.toDateString()) &&
        op.operation_type.toLowerCase().includes(filters.operationType.toLowerCase()) &&
        op.amount.toString().includes(filters.amount) &&
        getStatusText(op.status).toLowerCase().includes(filters.status.toLowerCase())
      );
    });
    setFilteredOperations(sortOperations(filtered));
    setCurrentPage(1);
  }, [filters, operations, sortConfig, sortOperations]);

  const indexOfLastOperation = currentPage * operationsPerPage;
  const indexOfFirstOperation = indexOfLastOperation - operationsPerPage;
  const currentOperations = sortOperations(filteredOperations).slice(indexOfFirstOperation, indexOfLastOperation);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleViewDetails = (operation) => {
    const updatedOperation = updateOperationStatus(operation);
    setSelectedOperation(updatedOperation);
    if (funds.length === 0) {
      fetchFunds();
    }
  };

  const closeDetails = () => {
    setSelectedOperation(null);
  };

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredOperations.map(op => ({
      Fecha: new Date(op.created_at).toLocaleString(),
      Comitente: op.comitente,
      Tipo: op.operation_type === 'suscripcion' ? 'Suscripción' : 'Rescate',
      Monto: op.amount,
      Fondo: op.fondo,
      Clase: op.clase,
      Estado: getStatusText(op.status)
    })));
    XLSX.utils.book_append_sheet(workbook, worksheet, "Operaciones de Fondos");
    XLSX.writeFile(workbook, "operaciones_fondos.xlsx");
  };

  const handleFilterChange = (field, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [field]: value
    }));
  };

  const getStatusText = (status) => {
    switch(status) {
      case 'completed': return 'Cumplida';
      case 'failed': return 'Fallida';
      case 'pending': return 'Pendiente';
      case 'cancelled': return 'Anulada';
      default: return status;
    }
  };

  const getStatusChip = (status) => {
    const text = getStatusText(status);
    let backgroundColor = '#e0e0e0';
    let color = '#000000';
    if (status === 'completed') {
      backgroundColor = '#c8e6c9';
      color = '#1b5e20';
    } else if (status === 'cancelled') {
      backgroundColor = '#fff9c4';
      color = '#f57f17';
    } else if (status === 'failed') {
      backgroundColor = '#ffcdd2';
      color = '#b71c1c';
    } else if (status === 'pending') {
      backgroundColor = '#bbdefb';
      color = '#0d47a1';
    }
    return (
      <Chip 
        label={text} 
        sx={{
          backgroundColor,
          color,
          '& .MuiChip-label': {
            padding: '0 6px',
          }
        }}
      />
    );
  };

  const updateOperationStatus = (operation) => {
    if (!operation.success) {
      return { ...operation, status: 'failed' };
    }
    return operation;
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Box sx={{ mb: 4, mt: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              Operaciones de Fondos
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleExportToExcel}
              sx={{ 
                boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                transition: 'all 0.3s',
                '&:hover': {
                  boxShadow: '0 6px 10px 4px rgba(33, 203, 243, .3)',
                  transform: 'translateY(-2px)',
                }
              }}
            >
              Exportar a Excel
            </Button>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper} sx={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Button onClick={() => requestSort('created_at')} sx={{ color: 'inherit', fontWeight: 'bold', mb: 1 }}>
                    Fecha {sortConfig.key === 'created_at' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                  </Button>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                    <DatePicker
                      value={filters.date}
                      onChange={(newValue) => handleFilterChange('date', newValue)}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          size="small"
                          sx={{
                            backgroundColor: 'white',
                            width: '100%',
                            '& .MuiInputBase-root': {
                              height: '32px',
                            },
                            '& .MuiInputBase-input': {
                              padding: '4px 8px',
                              fontSize: '0.85rem',
                            },
                            '& .MuiIconButton-root': {
                              padding: '4px',
                            },
                          }}
                        />
                      )}
                      format="dd/MM/yyyy"
                      slotProps={{
                        textField: {
                          placeholder: 'DD/MM/AAAA',
                        },
                        popper: {
                          sx: {
                            '& .MuiPaper-root': {
                              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                            },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>Comitente</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>Tipo</Typography>
                  <StyledTextField
                    size="small"
                    placeholder="Filtrar por tipo"
                    value={filters.operationType}
                    onChange={(e) => handleFilterChange('operationType', e.target.value)}
                    sx={{
                      backgroundColor: 'white',
                      width: '100%',
                      '& .MuiInputBase-root': {
                        height: '32px',
                      },
                      '& .MuiInputBase-input': {
                        padding: '4px 8px',
                        fontSize: '0.85rem',
                      },
                    }}
                  />
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>Monto</Typography>
                  <StyledTextField
                    size="small"
                    placeholder="Filtrar por monto"
                    value={filters.amount}
                    onChange={(e) => handleFilterChange('amount', e.target.value)}
                    sx={{ backgroundColor: 'white', width: '100%' }}
                  />
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Fondo</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Clase</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>Estado</Typography>
                  <StyledTextField
                    size="small"
                    placeholder="Filtrar por estado"
                    value={filters.status}
                    onChange={(e) => handleFilterChange('status', e.target.value)}
                    sx={{ backgroundColor: 'white', width: '100%' }}
                  />
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Acciones</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentOperations.map((op) => (
              <TableRow key={op.id}>
                <TableCell>{new Date(op.created_at).toLocaleDateString('es-AR', { day: '2-digit', month: '2-digit', year: 'numeric' })}</TableCell>
                <TableCell align="right">
                  <Link to={`/comitents/${op.comitente}`} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
                    {op.comitente}
                  </Link>
                </TableCell>
                <TableCell>{op.operation_type === 'suscripcion' ? 'Suscripción' : 'Rescate'}</TableCell>
                <TableCell align="right">${new Intl.NumberFormat('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(op.amount.replace(',', '.')))}</TableCell>
                <TableCell>{op.fondo}</TableCell>
                <TableCell>{op.clase}</TableCell>
                <TableCell>
                  {getStatusChip(op.status)}
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleViewDetails(op)} variant="outlined" size="small">
                    Ver Detalles
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={Math.ceil(filteredOperations.length / operationsPerPage)}
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
        />
      </Box>

      <Dialog open={!!selectedOperation} onClose={closeDetails}>
        <DialogTitle>Detalles de la Operación</DialogTitle>
        <DialogContent>
          {selectedOperation && (
            <>
              <Typography><strong>ID:</strong> {selectedOperation.id}</Typography>
              <Typography><strong>Tipo:</strong> {selectedOperation.operation_type === 'suscripcion' ? 'Suscripción' : 'Rescate'}</Typography>
              <Typography><strong>Monto:</strong> ${new Intl.NumberFormat('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(selectedOperation.amount.replace(',', '.')))}</Typography>
              <Typography><strong>Estado:</strong> {getStatusText(selectedOperation.status)}</Typography>
              <Typography><strong>Fondo:</strong> {selectedOperation.fondo}</Typography>
              <Typography><strong>Clase:</strong> {selectedOperation.clase}</Typography>
              <Typography><strong>Fecha de creación:</strong> {new Date(selectedOperation.created_at).toLocaleString()}</Typography>
              {selectedOperation.details && <Typography><strong>Detalles:</strong> {selectedOperation.details}</Typography>}
              {(selectedOperation.operation_type === 'suscripcion' || selectedOperation.operation_type === 'rescate') && (
                <Box mt={2}>
                  <Typography variant="h6">Fondos Disponibles</Typography>
                  {funds.length > 0 ? (
                    <ul>
                      {funds.map(fund => (
                        <li key={fund.id}>{fund.nombre}</li>
                      ))}
                    </ul>
                  ) : (
                    <Typography>Cargando fondos...</Typography>
                  )}
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDetails}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default FundOperations;
