import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  CircularProgress,
  Box,
  Button,
  Drawer,
  styled,
  Chip
} from '@mui/material';
import { Link } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  '&.MuiTableCell-head': {
    fontSize: '0.95rem',
  },
}));

function MarketOperations() {
  const [operations, setOperations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState(null);

  useEffect(() => {
    const fetchMarketOperations = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/market-operations`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch market operations');
        }

        const data = await response.json();
        if (data.IsOK) {
          setOperations(data.Resultado);
        } else {
          throw new Error('API response was not OK');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMarketOperations();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const getOperationType = (type) => {
    return type === '1' ? 'Compra' : 'Venta';
  };

  const getStatusChip = (status) => {
    let text = status;
    let backgroundColor = '#e0e0e0';
    let color = '#000000';
    if (status === 'Cumplida') {
      backgroundColor = '#c8e6c9';
      color = '#1b5e20';
    } else if (status === 'Anulada') {
      backgroundColor = '#fff9c4';
      color = '#f57f17';
    } else if (status === 'Fallida') {
      backgroundColor = '#ffcdd2';
      color = '#b71c1c';
    } else if (status === 'Pendiente') {
      backgroundColor = '#bbdefb';
      color = '#0d47a1';
    }
    return (
      <Chip 
        label={text} 
        sx={{
          backgroundColor,
          color,
          '& .MuiChip-label': {
            padding: '0 6px',
          }
        }}
      />
    );
  };

  const handleOpenDrawer = (operation) => {
    setSelectedOperation(operation);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedOperation(null);
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Typography variant="h4" gutterBottom>
        Operaciones de Mercado
      </Typography>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Typography variant="subtitle2">ID</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle2">Comitente</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle2">Fecha</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle2">Tipo</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle2">Ticker</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle2">Cantidad</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle2">Precio</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle2">Total</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle2">Estado</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle2">Número de Orden</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle2">Acciones</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {operations.map((operation) => (
              <TableRow key={operation.id}>
                <TableCell>{operation.id}</TableCell>
                <TableCell align="right">
                  <Link to={`/comitents/${operation.comitente}`} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
                    {operation.comitente}
                  </Link>
                </TableCell>
                <TableCell>{formatDate(operation.created_at)}</TableCell>
                <TableCell>{getOperationType(operation.operation_type)}</TableCell>
                <TableCell>{operation.ticker}</TableCell>
                <TableCell align="right">{operation.quantity}</TableCell>
                <TableCell align="right">{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(operation.price.replace(',', '.')))}</TableCell>
                <TableCell align="right">{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(operation.price.replace(',', '.')) * operation.quantity)}</TableCell>
                <TableCell>{getStatusChip(operation.market_status?.estado || 'N/A')}</TableCell>
                <TableCell align="right">{operation.numero_orden || 'N/A'}</TableCell>
                <TableCell>
                  <Button variant="outlined" onClick={() => handleOpenDrawer(operation)}>
                    Ver Detalle
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
      >
        <Box sx={{ width: 400, p: 3 }}>
          {selectedOperation && (
            <>
              <Typography variant="h6" gutterBottom>Detalles de la Operación</Typography>
              <Typography><strong>Comitente:</strong> {selectedOperation.comitente}</Typography>
              <Typography><strong>Fecha:</strong> {formatDate(selectedOperation.created_at)}</Typography>
              <Typography><strong>Tipo:</strong> {getOperationType(selectedOperation.operation_type)}</Typography>
              <Typography><strong>Ticker:</strong> {selectedOperation.ticker}</Typography>
              <Typography><strong>Cantidad:</strong> {selectedOperation.quantity}</Typography>
              <Typography><strong>Precio:</strong> {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(selectedOperation.price.replace(',', '.')))}</Typography>
              <Typography><strong>Total:</strong> {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(selectedOperation.price.replace(',', '.')) * selectedOperation.quantity)}</Typography>
              <Typography><strong>Estado:</strong> {selectedOperation.market_status?.estado || 'N/A'}</Typography>
              {/* Add more details as needed */}
            </>
          )}
        </Box>
      </Drawer>
    </Box>
  );
}

export default MarketOperations;
